import React from "react";
import Layout from "../../../components/layout";
import Navigation from "../../../components/Navigation";
import Button from "@material-ui/core/Button";
import Icon1 from "./klassenbuch_icon1.svg";
import Icon2 from "./klassenbuch_icon2.svg";
import Icon3 from "./klassenbuch_icon3.svg";
import Icon4 from "./klassenbuch_icon4.svg";
import Bild1 from "./Klassenbuch_Bild1.png";
import Bild2 from "./Klassenbuch_Bild2.png";


export default function Klassenbuch() {
    return (
        <Layout parentPath="/" header="Elektronische Unterrichtsdokumentation">
            <Navigation path="/info/produkte/klassenbuch" />
            <div className="produkteContent">
                <p>Dokumentieren Sie <b>alltägliche Schulaktivitäten einfach digital</b>. Egal, ob in der Schule oder unterwegs, am Computer oder vom Handy aus. Pflegen Sie Ihre Unterrichtsdokumentation einfach und bequem von überall. Wir legen höchsten Wert auf <b>Datensicherheit</b>. Deswegen werden die Daten bereits vor der Datenübertragung <b>mit modernsten Technologien verschlüsselt</b> und absolut sicher gespeichert.</p>
                <div className="buttonBar">
                    <Button href="/kostenlos-testen" variant="contained">Jetzt kostenlos testen &nbsp;>>></Button>
                    <Button href="https://fehlzeitenerfassung.de/app" variant="contained">Sofort starten &nbsp;>>></Button>
                </div>
                <div className="produkteIcons">
                    <table className="iconTable">
                        <tr>
                            <td>
                                <img className="produkteIcon" src={Icon1} alt="Icon1" />
                                <p>
                                    Mit unserem digitalen Klassenbuch kann der Lehrstoff im Unterricht einfach dokumentiert werden. Dadurch, dass die Eintragungen mit einer persönlichen Signatur (elektronische Unterschrift) verschlüsselt werden, ist auch der <b>digitale Eintrag rechtssicher</b>.
                                </p>
                            </td>
                            <td>
                                <img className="produkteIcon" src={Icon2} alt="Icon2" />
                                <p>
                                    Sie brauchen kein VPN und müssen nicht mehr im Schulnetzwerk bleiben. Mit unserer Verschlüsselungtechnologie können Sie <b>überall die Daten eintragen</b>.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img className="produkteIcon" src={Icon3} alt="Icon3" />
                                <p>
                                    Das Klassenbuch von felzeitenerfassung.de ist <b>vollständig DSGVO-Konform</b>! Der Schutz personenbezogener Daten hat für uns oberste Priorität, deswegen wurde unsere Software nach dem <a href="https://www.datenschutzzentrum.de/sdm/" target="_blank">Standard-Datenschutzmodell</a> entwickelt.
                                </p>
                            </td>
                            <td>
                                <img className="produkteIcon" src={Icon4} alt="Icon4" />
                                <p>
                                    Unsere Software ist intuitiv. Sie brauchen keine Schulung, um die Software bedienen zu können.
                                </p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="bild1">
                    <div className="produkteBild1">
                        <img className="produkteBild1" src={Bild1} alt="Bild 1" />
                    </div>
                    <div className="bild1Content">
                        <ul>
                            <li>
                                Lehrstoff festhalten
                            </li>
                            <li>
                                Hausaufgaben notieren
                            </li>
                            <li>
                                Vertretungskraft eintragen
                            </li>
                            <li>
                                Bemerkungen zum Unterricht eintragen
                            </li>
                            <li>
                                Mit <a href="https://fehlzeitenerfassung.de/info/produkte/fehlzeitenerfassung/">Fehlzeitenerfassung Software integrierbar</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bild2">
                    <div className="bild2Content">
                        <p>
                            Mit einem Klick können Sie das digitale Klassenbuch auch einfach ausdrucken.
                        </p>
                    </div>
                    <div className="produkteBild2">
                        <img className="produkteBild2" src={Bild2} alt="Bild 2" />
                    </div>
                </div>
                <div className="buttonBar">
                    <Button href="/kostenlos-testen" variant="contained">Jetzt kostenlos testen &nbsp;>>></Button>
                    <Button href="https://fehlzeitenerfassung.de/app" variant="contained">Sofort starten &nbsp;>>></Button>
                    {/*<Button href="/info/preise" variant="contained">Unsere Preise &nbsp;</Button>*/}
                </div>
            </div>
        </Layout>
    );
}


